.content {
  width: 400px;
  padding: 20px;
}

.statusContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.arrow {
  margin-left: 25px;
  margin-right: 25px;
}
