.ant-switch {
	background-color: var(--color-text-300) !important;

	&.ant-switch-checked {
		background-color: var(--color-primary-base) !important;

		.ant-switch-handle {
			left: calc(100% - 18px);
			&::before {
				background: var(--color-white) !important;
			}
		}
	}
}
