.errorText {
    margin-top: 6px;
    font-family: var(--font-family) !important;
    font-weight: normal;
    font-size: var(--font-size-xs);
    line-height: var(--font-line-height-xxs);
    color: var(--color-status-danger-base) !important;
}

.spinner {
    position: absolute !important;
    top: 11px !important;
    right: 12px !important;
    z-index: 5;
    background: var(--color-primary-base);

    > div {
        margin: 0 !important;
    }
}

.option {
    font-family: var(--font-family) !important;
    font-style: normal !important;
    font-weight: var(--font-weight-light) !important;
    font-size: var(--font-size-xs) !important;
    background-color: var(--color-background-01);
    > div {
        display: flex;
        align-items: center;
    }
}

.div-avatar {
    margin-left: 8px;
}

.avatarOpt {
    padding-top: 3px !important;
}

.borderRed {
    border: 1px solid var(--color-status-danger-base) !important;
}

.divDropdown {
    width: 100%;
    .div-flag {
        width: 28px;
        height: 21px;
        border-radius: 12px;
        background: var(--color-text-600);
        border: 2px solid var(--color-text-600);

        position: absolute;
        top: 9.5px;
        left: 12px;
        z-index: 1;
    }
    .divContent {
        display: flex;
        align-items: center;

        > p {
            color: var(--color-text-50) !important;
            font-weight: var(--font-weight-light) !important;
            font-size: var(--font-size-xs) !important;
        }

        > i {
            margin-right: 13px;
        }
    }

    .statusContent {
        display: flex;
        align-items: center;
        min-height: 26px !important;
        height: 26px !important;
    }
}
