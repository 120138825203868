.Container {
  width: 92px;
  height: 30px;
  border: 1px solid var(--color-background-04);
  border-radius: 8px;

  display: flex;
  z-index: 2;

  & div:first-child .item {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  & div:last-child .item {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .item {
    z-index: 1;
    width: 30px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    transition: background-color 0.5s;

    &:hover {
      background-color: var(--color-background-03);
    }

    p {
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      margin: 0px;
      color: var(--color-text-300);
    }
  }
}

.Undetermined {
  width: 62px !important;
}

.value-false {
  .false {
    background-color: var(--color-status-danger-base) !important;
    i {
      color: var(--color-white) !important;
    }
  }
}

.value-true {
  .true {
    background-color: var(--color-status-success-base) !important;

    i {
      color: var(--color-white) !important;
    }
  }
}

.value-inherited {
  .inherited {
    background-color: var(--color-status-success-base) !important;

    p {
      color: var(--color-white) !important;
    }
  }
}

.value-not-inherited {
  .inherited {
    background-color: var(--color-status-danger-base) !important;

    p {
      color: var(--color-white) !important;
    }
  }
}
