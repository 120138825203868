.div-company-primary {
  border-radius: 4px;
  border: 1px solid var(--color-primary-base);

  color: var(--color-primary-base);

  padding-left: 6px;
  padding-right: 6px;

  margin-right: 10px;
}

.div-company-secondary {
  border-radius: 4px;

  color: var(--color-text-50) !important;
  background-color: var(--color-text-600) !important;

  padding-left: 6px;
  padding-right: 6px;

  margin-right: 10px;
}

.div-rightIcon {
  margin-left: 10px !important;
  margin-right: 4px !important;
  border: none !important;
}

.variable {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

  padding: 0 !important;
  padding-left: 10px !important;
  padding-right: 10px !important;

  height: 36px !important;
  width: auto !important;

  border-radius: 8px !important;

  > span {
    display: flex !important;
  }
}
