/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: var(--color-primary-base);

  position: fixed;
  top: 50px;
  left: 225px;

  width: calc(100% - 225px);
  height: 4px;
}

#nprogress .peg {
  display: block;
  position: absolute;
	background: transparent;
  left: 100%;
  width: 100%;
  height: 100%;
  opacity: 1.0;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}