.divider {
  background-color: var(--color-background-04);
  height: 1px;
  width: 100%;
  margin-bottom: 20px;
}

.checkbox {
  > div {
    background-color: var(--color-background-03);
    padding: 14px 20px;

    border-radius: 4px;
  }
}

.footer-button {
  display: flex;

  > button:first-child {
    margin-right: 10px;
  }
}
