ul.ant-pagination {
  margin: 0px !important;
  background: var(--color-background-01) !important;
  border-top: none !important;
  position: relative;

  .ant-pagination-next {
    .ant-pagination-item-link {
      background: none !important;
    }
  }

  .ant-pagination-disabled {
    background: none !important;
    .ant-pagination-item-link::before {
      color: var(--color-text-400) !important;
    }
    .ant-pagination-item-link {
      background: none !important;
    }
  }

  .ant-pagination-options {
    display: none;
  }

  li {
    height: 31px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: var(--font-weight-light);
    font-size: var(--font-size-xs);
    border: none !important;
    border-radius: 8px !important;
    background-color: transparent !important;

    &:hover {
      background: var(--color-text-600) !important;
    }

    &.ant-pagination-item {
      a {
        display: flex;

        height: 100%;
        width: 100%;

        justify-content: center;
        align-items: center;

        padding: 0;

        font-family: var(--font-family);
        font-style: normal;
        font-weight: var(--font-weight-light);
        font-size: var(--font-size-xs);
        line-height: var(--font-line-height-xxs);

        color: var(--color-text-50);

        &:hover {
          color: var(--color-text-50);
        }
      }
    }

    &.ant-pagination-jump-prev {
      background: transparent !important;
      svg {
        display: none;
      }

      .ant-pagination-item-ellipsis {
        user-select: none !important;
      }

      &:focus {
        .ant-pagination-item-ellipsis {
          opacity: 1;
        }
      }

      .ant-pagination-item-container {
        border-radius: 8px !important;
        height: 100% !important;
      }

      &:hover {
        .ant-pagination-item-ellipsis {
          opacity: 0;
        }
        .ant-pagination-item-container {
          background: var(--color-text-600) !important;
          &::before {
            font-family: 'icomoon' !important;
            content: '\e924';
            color: var(--color-text-400);

            font-size: var(--font-size-md);
          }
        }
      }
    }

    &.ant-pagination-jump-next {
      background: transparent !important;
      svg {
        display: none;
      }

      .ant-pagination-item-ellipsis {
        user-select: none !important;
      }

      &:focus {
        .ant-pagination-item-ellipsis {
          opacity: 1;
        }
      }

      .ant-pagination-item-container {
        border-radius: 8px !important;
        height: 100% !important;
      }

      &:hover {
        .ant-pagination-item-ellipsis {
          opacity: 0;
        }
        .ant-pagination-item-container {
          background: var(--color-text-600) !important;
          &::before {
            font-family: 'icomoon' !important;
            content: '\e91b';
            color: var(--color-text-400);
            font-size: var(--font-size-md);
          }
        }
      }
    }

    &.ant-pagination-item-active {
      a {
        color: var(--color-primary-base) !important;
        font-weight: var(--font-weight-bold);
      }
    }

    &.ant-pagination-prev {
      button {
        border: none !important;
        display: flex;
        border-radius: 8px !important;

        justify-content: center;

        font-family: 'icomoon' !important;
        font-size: var(--font-size-md);
        background-color: transparent !important;

        &::before {
          content: '\e91a';
          color: var(--color-text-400);
        }

        span {
          svg {
            display: none;
          }
        }
      }
      &:hover {
        button {
          background: var(--color-text-600) !important;
        }
      }
    }

    &.ant-pagination-next {
      button {
        border: none !important;
        display: flex;
        border-radius: 8px !important;

        justify-content: center;

        font-family: 'icomoon' !important;
        font-size: var(--font-size-md);

        &::before {
          content: '\e91b';
          color: var(--color-text-400);
        }

        span {
          svg {
            display: none;
          }
        }
      }

      &:hover {
        button {
          background: var(--color-text-600) !important;
        }
      }
    }
  }
}

.ant-select-open {
  .ant-select-selection-item {
    color: var(--color-text-50) !important;
  }
}

.ant-select-item-option-content {
  color: var(--color-text-50) !important;
}

.pagination-select {
  border: 1px solid var(--color-background-06) !important;
  border-radius: 8px !important;

  .ant-select-selector {
    border: none !important;
    border-radius: 8px !important;
    box-shadow: none !important;
    background-color: transparent !important;
  }

  .ant-select-arrow {
    display: none;
  }
}

.ant-select-open {
  border: 1px solid var(--color-primary-base) !important;
}

.ant-select-item-option-active:not(.dropdown-empty):not(.dropdown-body-spinner):not(.ant-select-item-option-selected) {
  background: var(--color-text-600) !important;
}

.ant-select-item-option-selected {
  background-color: var(--color-text-700) !important;
}
