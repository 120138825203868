.Content {
  display: flex;
  height: calc(100vh - 50px);
  max-height: calc(100vh - 50px);
  background: var(--color-background-01);

  .sideMenu {
    height: calc(100% - 50px);
    background: var(--color-background-01);
    z-index: 1032;
    padding: 14.5px;
    position: relative;
    width: 100%;

    display: flex;
    align-items: center;
    flex-direction: column;

    overflow: auto;
    overflow-x: hidden;

    .side-menu-items {
      width: 100% !important;
      margin-bottom: 3px;
    }

    .collapsed-side-menu-items {
      width: 45px !important;
    }

    .sector-menu {
      position: relative;
      width: 100%;
    }

    .separator {
      width: calc(100% + 30px);
      height: 1px;
      background: var(--color-background-04);
      margin-left: -15px;
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }

  .main {
    width: calc(100% - 225px);
    height: calc(100vh - 50px);
    max-height: calc(100vh - 48px);
    overflow: auto;
    background: var(--color-background-00);
    transition: width 0.5s;
  }

  .collapsed-main {
    width: 100% !important;
    transition: none !important;
  }

  .footer {
    width: 100%;
    height: 50px;
    border-top: 1px solid var(--color-background-04);
    display: flex;
    justify-content: flex-end;
    align-items: center;

    padding: 13px;

    z-index: 9999;

    background: var(--color-background-01);
  }

  .collapsed-footer {
    justify-content: center;
    padding: 0 !important;
  }
}
