.success-status:not(.darkmode) {
  background: var(--color-status-success-t15) !important;
  color: var(--color-status-success-base) !important;
}
.danger-status:not(.darkmode) {
  background: var(--color-status-danger-t15) !important;
  color: var(--color-status-danger-base) !important;
}
.attention-status:not(.darkmode) {
  background: var(--color-status-warning-t15) !important;
  color: var(--color-status-warning-base) !important;
}
.primary-status:not(.darkmode) {
  background: var(--color-status-primary-t15) !important;
  color: var(--color-status-primary-base) !important;
}
.default-status:not(.darkmode) {
  background: var(--color-status-default-t15) !important;
  color: var(--color-status-default-base) !important;
}
.disabled-status:not(.darkmode) {
  background: var(--color-status-disabled-t15) !important;
  color: var(--color-status-disabled-base) !important;
}

.darkmode.success-status {
  background: var(--color-status-success-t30) !important;
  color: var(--color-status-success-base) !important;
}
.darkmode.danger-status {
  background: var(--color-status-danger-t30) !important;
  color: var(--color-status-danger-base) !important;
}
.darkmode.attention-status {
  background: var(--color-status-warning-t30) !important;
  color: var(--color-status-warning-base) !important;
}
.darkmode.primary-status {
  background: var(--color-status-primary-t30) !important;
  color: var(--color-status-primary-base) !important;
}
.darkmode.default-status {
  background: var(--color-status-default-t30) !important;
  color: var(--color-status-default-base) !important;
}
.darkmode.disabled-status {
  background: var(--color-status-disabled-t30) !important;
  color: var(--color-status-disabled-base) !important;
}
