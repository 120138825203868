.footer-button {
  display: flex;
  > button:first-child {
    margin-right: 10px;
  }
}

.text-input {
  width: 32% !important;
}
