.margin-bottom {
  margin-bottom: 20px;
}
.Container:not(.margin-bottom) {
  margin-bottom: 0px;
}
.spinner {
  position: absolute !important;
  top: 8px !important;
  right: 12px !important;
  z-index: 5;

  > div {
    margin: 0 !important;
  }
}
.Container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  .icon-with-tooltip {
    margin-left: 5px;
  }

  .input-container {
    position: relative;
    min-width: 100px;
    width: 100%;

    .Icon {
      position: absolute;
      right: 12px;
      top: 10px;
    }

    .supportText {
      position: absolute;
      display: flex;
      align-items: center;
      height: 36px;
      right: 0;
      padding-left: 16px;
      padding-right: 16px;
      border-left: 1px solid var(--color-text-600);
      color: var(--color-text-400);
      background-color: var(--color-status-disabled-t15);
      z-index: 9;
    }

    .errorText {
      margin-top: 5px;
      margin-bottom: 0 !important;
      font-family: var(--font-family);
      font-weight: var(--font-weight-light);
      font-size: var(--font-size-xs);
      line-height: var(--font-line-height-xxs);
      color: var(--color-status-danger-base);
    }

    .Left-Icon {
      position: absolute;
      left: 12px;
      top: 10px;
      z-index: 9999;
    }

    .TextInput {
      height: 36px !important;
      background-color: var(--color-background-01);
      box-shadow: none !important;
      border: 1px solid var(--color-background-06);
      border-radius: 8px !important;

      font-family: var(--font-family) !important;
      font-size: var(--font-size-xs) !important;
      line-height: var(--font-line-height-xxs) !important;
      font-weight: normal;
      color: var(--color-text-50) !important;

      &::placeholder {
        color: var(--color-text-400) !important;
      }

      &:focus {
        border: 1px solid var(--color-text-400) !important;
      }

      &:disabled {
        background-color: var(--color-status-disabled-t15) !important;
        border: 1px solid var(--color-status-disabled-t45) !important;
      }
    }
  }

  .error {
    .TextInput {
      border: 1px solid var(--color-status-danger-base) !important;

      &:hover {
        border: 1px solid var(--color-status-danger-base) !important;
      }

      &:focus {
        border: 1px solid var(--color-status-danger-base) !important;
      }
    }
  }

  .success {
    .TextInput {
      border: 1px solid var(--color-status-success-base) !important;

      &:hover {
        border: 1px solid var(--color-status-success-base) !important;
      }

      &:focus {
        border: 1px solid var(--color-status-success-base) !important;
      }
    }
  }

  .with-left-icon {
    .TextInput {
      padding-left: 40px;
    }
  }

  .with-right-icon {
    .TextInput {
      padding-right: 32px;
    }
  }

  .disabled {
    .Left-Icon {
      color: var(--color-text-400) !important;
      cursor: no-drop !important;
      z-index: auto !important;
    }
  }

  .div-label {
    display: flex;
    .label {
      margin-right: 3px;
    }
  }
}

.input-clean > div:last-child > input {
  background: transparent !important;
  border: none !important;
}
