.error-message {
	background: var(--color-status-danger-base) !important;
}
.success-message {
	background: var(--color-status-success-base) !important;
}
.warning-message {
	background: var(--color-status-warning-base) !important;
}
.brand-message {
	background: var(--color-primary-base) !important;
}

.open {
	display: flex !important;
}

.close {
	display: flex !important;

	width: 100%;

	align-items: center;
	justify-content: center;

	animation: animation-message-close linear;
	animation-duration: 0.5s;
	animation-fill-mode: forwards;
}

@keyframes animation-message-close {
	from {
		height: 40px;
	}
	to {
		height: 0px;
	}
}
