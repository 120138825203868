.ant-popover-inner-content {
  padding: 0 !important;
}

.ant-popover-inner {
  border-radius: 8px !important;
}
.ant-popover-title {
  border-bottom: none !important;
  min-width: 150px !important;
}

.ant-popover {
  z-index: 99999 !important;
}
