::-webkit-scrollbar {
  width: 5px !important;

  margin-top: 8px !important;
  margin-bottom: 8px !important;

  background: var(--color-text-600) !important;
}

::-webkit-scrollbar-thumb {
  background: var(--color-text-400) !important;
  border-radius: 8px !important;
}
