.badge {
	display: flex !important;

	width: 23px !important;
	height: 19px !important;

	margin-left: 8px !important;

	font-family: 'Open Sans';
	font-style: normal;
	font-weight: var(--font-weight-bold) !important;
	font-size: var(--font-size-xs) !important;

	align-items: center !important;
	justify-content: center !important;

	border-radius: 16px !important;

	background: transparent;

	transition: 0.4s !important;
}

.large-badge {
	width: 37px !important;
}
.medium-badge {
	width: 30px !important;
}

.badge-primary {
	background: var(--color-background-02) !important;
	color: var(--color-primary-base) !important;
}

.badge-text {
	border: 1px solid var(--color-text-50) !important;
}

.badge-link {
	border: 1px solid var(--color-primary-base) !important;
}

.badge-link {
	border: none;
	background-color: transparent;
}