.company-button {
  height: 29px !important;
  > span {
    display: flex !important;
  }
}

.line {
  width: 100%;

  height: 1px;

  background: var(--color-background-04);

  margin-bottom: 3px;
}

.title {
  padding: 10px 10px 8px 10px;
  white-space: nowrap;
}

.divTitle {
  padding-left: 10px;
  padding-right: 10px;
}

.divContent {
  padding: 0 10px 10px 10px;
}

.button {
  height: 29px !important;
  width: 100% !important;

  justify-content: initial !important;
  margin-top: 5px;
  background: transparent !important;
  box-shadow: none !important;
}
