.status {
  height: 24px !important;
  width: fit-content;

  display: flex;
  align-items: center;

  border-radius: 8px !important;

  padding: 10px !important;

  border: none !important;

  position: relative;

  .button-spinner {
    margin: 0 !important;
    > div {
      height: 10px !important;
      width: 10px !important;
      border: 2px solid var(--color-primary-base);
      border-color: var(--color-primary-base) transparent transparent
        transparent;
    }
  }
}

.status-with-rightIcon {
  padding: 0 5px 0 10px !important;
}

.waitingApproval-status {
  background: var(--color-status-default-t15) !important;
  color: var(--color-status-default-base) !important;
  border: none;
}

.disapproved-status {
  background: var(--color-status-danger-t15) !important;
  color: var(--color-status-danger-base) !important;
  border: none;
}

.approved-status {
  background: var(--color-status-warning-t15) !important;
  color: var(--color-status-warning-base) !important;
  border: none;
}

.confirmedProvider-status {
  background: var(--color-status-success-t15) !important;
  color: var(--color-status-success-base) !important;
  border: none;
}

.partialReceived-status {
  background: var(--color-status-warning-t15) !important;
  color: var(--color-status-warning-base) !important;
  border: none;
}

.totalReceived-status {
  background: var(--color-status-success-t15) !important;
  color: var(--color-status-success-base) !important;
  border: none;
}

.drafit-status {
  background: var(--color-status-default-t15) !important;
  color: var(--color-status-default-base) !important;
  border: none;
}
