.ant-input-textarea-show-count::after {
  position: absolute !important;

  top: 0px !important;
  right: 0px !important;

  padding: 0 8px !important;

  border-radius: 20px !important;

  color: var(--color-text-50) !important;

  font-style: normal !important;
  font-weight: var(--font-weight-extrabold) !important;
  font-size: var(--font-size-xs) !important;
  line-height: var(--font-size-sm) !important;

  letter-spacing: -0.05em !important;
}
