.icon-container {
  min-width: 36px;
  min-height: 36px;

  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}
