.divDropdown {
  border-radius: 8px !important;
  padding: 0px;

  .title {
    display: flex;
    height: 28px;

    align-items: center;
    padding: 0 12px;

    > p {
      user-select: none;
    }
  }

  .dropdownLabel {
    width: 150px;
    height: 36px;

    display: flex;

    align-items: center;

    .icon {
      margin-right: 12px;
    }
    > span {
      display: flex;
    }
  }
}
