.divTitle {
  height: 28px;
  width: 150px;
  display: flex;
  align-items: center;

  padding-left: 12px;
  margin-top: -10px;

  padding-top: 8px;
}

.content {
  margin-left: 12px;
}

.divContent {
  width: 150px;
  align-items: center;

  padding-bottom: 8px;

  .status {
    height: 35px;
    width: 100%;
    display: flex;
    align-items: center;

    cursor: pointer;
    > button {
      margin-left: 16px;
    }

    &:hover {
      background: var(--color-text-600);
    }
  }
}

.divContentSituation {
  width: 180px;
  align-items: center;

  padding-bottom: 8px;

  .status {
    height: 35px;
    width: 100%;
    display: flex;
    align-items: center;

    cursor: pointer;
    > button {
      margin-left: 16px;
    }

    &:hover {
      background: var(--color-text-600);
    }
  }
}
