.divSecurity {
	width: 350px;

	.input {
		margin-bottom: -3px !important;
	}

	.last-input {
		margin-bottom: 7px !important;
	}
}
