.container {
	display: flex;

	align-items: center;

	justify-content: space-between;

	background: var(--color-text-600);

	height: 48px;
	width: 100%;

	border-radius: 4px;

	padding-left: 20px;
	padding-right: 30px;

	margin-bottom: 15px;
	
	.description {
		position: absolute;
		left: 213px;
	}

	.rightItem {
		display: flex;
		justify-content: flex-end;
	}
}