.loader {
	display: flex;
	position: relative;

	margin: auto 21px auto 12px;

	justify-content: center;
	align-items: center;

	width: 20px;
	height: 20px;

	> div {
		box-sizing: border-box;

		display: block;
		position: absolute;

		border: 3px solid var(--color-primary-base);
		border-color: var(--color-primary-base) transparent transparent transparent;

		width: 15px;
		height: 15px;

		border-radius: 50%;

		animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	}
}

.loader div:nth-child(1) {
	animation-delay: -0.45s;
}
.loader div:nth-child(2) {
	animation-delay: -0.3s;
}
.loader div:nth-child(3) {
	animation-delay: -0.15s;
}

@keyframes loader {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
