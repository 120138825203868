.circleAvatar {
	margin: auto 25px auto 25px;
	cursor: pointer;
}

.divContentItem {
	border: 1px solid var(--color-background-04) !important;
	border-bottom: 0.5px solid var(--color-background-04) !important;
	> div {
		margin: 5px 5px 0px 5px;
	}

	.divItem {
		display: flex;
		cursor: pointer !important;

		align-items: center;
		background: var(--color-background-01);
		&:hover {
			background: var(--color-text-600);
		}

		.icon {
			margin-left: 16px;
			margin-right: 10px;
		}

		.itemLink {
			width: fit-content;
			user-select: none;

			height: 39px;

			display: flex;

			align-items: center;
		}
	}

	.divItem-help {
		margin-bottom: 5px !important;
	}

	.divItem-exit {
		&:hover {
			background: var(--color-status-danger-t15) !important;
			> i,
			p {
				color: var(--color-status-danger-base) !important;
			}
		}
	}
}

.divContentItem-exit {
	padding-bottom: 5px;
	border: 1px solid var(--color-background-04) !important;
	border-top: 0.5px solid var(--color-background-04) !important;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
}

.divTitle {
	padding-right: 40px !important;
	display: flex;
	border-top-right-radius: 8px;
	border-top-left-radius: 8px;
	border: 1px solid var(--color-background-04) !important;
	border-bottom: none !important;

	.divAvatar {
		height: 78px !important;
		width: 83px !important;

		display: flex !important;

		align-items: center !important;
		justify-content: center !important;

		> span {
			margin-left: 3px !important;
		}
	}

	.divAvatarItem {
		margin: 0;
		margin-top: 21px;
		margin-bottom: 8px;

		.name {
			display: flex;
		}
	}
}