.divTab {
	height: 0;

	overflow: hidden;
	border-radius: 5px;

	user-select: none;

	.tab-tr {
		height: 50px;
	}
}

.tab {
	position: relative;
	background: var(--color-background-01);
	width: 245px;
	padding-left: 25px;

	cursor: pointer;

	&:hover {
		background: var(--color-text-600);
	}

	> p {
		width: fit-content;
	}
}

.divColor {
	position: absolute;

	background: var(--color-primary-base);

	width: 4px;
	height: 100%;

	left: 0;
	top: 0;
}
