.ant-checkbox {
	&:not(.ant-checkbox-indeterminate):not(.ant-checkbox-checked):not(.ant-checkbox-disabled) {
		.ant-checkbox-inner {
			border-color: var(--color-text-300) !important;
			background: var(--color-background-01) !important;
		}
		&:hover {
			.ant-checkbox-inner {
				border-color: var(--color-text-100) !important;
			}
		}
	}
}

.ant-checkbox-disabled {
	.ant-checkbox-inner {
		border-color: var(--color-status-disabled-base) !important;
		background: var(--color-status-disabled-t30) !important;
		color: var(--color-status-disabled-base) !important;
	}
}

.ant-checkbox-checked:not(.ant-checkbox-disabled) {
	.ant-checkbox-inner {
		background: var(--color-primary-base) !important;
		border-color: var(--color-primary-base) !important;
		color: var(--color-white) !important;
	}
}

.ant-checkbox-indeterminate {
	.ant-checkbox-inner::after {
		width: 10px !important;
		height: 1.5px !important;
		background: var(--color-status-disabled-base) !important;
	}

	&:not(.ant-checkbox-disabled) {
		.ant-checkbox-inner {
			background: var(--color-primary-base) !important;
		}
		.ant-checkbox-inner::after {
			background: var(--color-white) !important;
		}
	}
}

.ant-checkbox-inner {
	border-radius: 4px !important;
}

.ant-checkbox-checked::after {
	border: none !important;
}
