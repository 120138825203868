.ant-collapse-item-active {
    & > .ant-collapse-header {
        &:hover {
            //background-color: var(--color-background-02);
        }

        & > div > .chevron {
            transform: rotate(90deg);
        }
    }
}
