.ant-select-selection-overflow-item-suffix {
  position: unset !important;
}

.ant-select-selector {
  .ant-select-selection-overflow {
    width: 100% !important;
    display: flex;
    flex-wrap: nowrap !important;

    &::-webkit-scrollbar {
      height: 3px;
    }

    .ant-select-selection-overflow-item {
      width: fit-content !important;
    }
  }

  .ant-select-selection-search {
    margin-inline-start: 0 !important;
    width: 100% !important;
  }
  .ant-select-selection-placeholder {
    color: var(--color-text-400) !important;
    font-size: var(--font-size-xs) !important;
    font-weight: var(--font-weight-light) !important;
    line-height: var(--font-line-height-xxs);
  }
}

.ant-select-item-option-state {
  display: none !important;
}

.dropdown-body-spinner {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 0 !important;

  width: 0 !important;

  > div {
    margin: 0 !important;
  }
}

.ant-select-item-empty .ant-empty {
  display: none !important;
}

.ant-select-selection-item {
  > div {
    .input-with-search-subLabel {
      display: none !important;
    }
  }
}

.ant-select-single {
  .ant-select-selection-search-input {
    height: 100% !important;
    padding-left: 26px !important;
  }
}

.ant-select-multiple .ant-select-selection-placeholder {
  padding-left: 26px !important;
  left: 14px !important;
}
