.ant-collapse:not(.collapse-withoutCollapse)
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  position: unset !important;
  padding: 0 10px 0 20px;
}

.collapse-withoutCollapse {
  .ant-collapse-header {
    cursor: default !important;
    margin-left: 16px !important;

    .ant-collapse-arrow {
      padding: 0 !important;
      .icon {
        display: none !important;
      }
    }
  }
}

.Card-inside-arrow {
  .ant-collapse-header {
    .ant-collapse-arrow {
      padding: 0 0px 0 2px !important;
      margin-right: 4px !important;
      vertical-align: 0px !important;
      .icon {
        display: flex !important;
      }
    }
  }
  .ant-collapse-item-active {
    .ant-collapse-header {
      border-top: 1px solid var(--color-background-04) !important;
    }
  }
}

.ant-collapse-item-active {
  .ant-collapse-header {
    padding: 10px 0 0 0 !important;

    align-items: center;

    height: 60px !important;
  }
}

.ant-collapse-header {
  display: flex !important;
  align-items: center !important;

  padding: 0 !important;

  height: 70px !important;
  > p {
    user-select: none;
  }
}

.ant-collapse-content-active {
  background: var(--color-background-01) !important;
  border-top: 1px solid var(--color-background-04) !important;
  > div {
    padding: 0;
  }
}

.ant-collapse-content {
  margin: 0 25px 0 16px;
}

.ant-collapse-content-box {
  padding: 0 !important;
}
