html {
  --font-size-xxl: 32px;
  --font-size-xl: 24px;
  --font-size-l: 20px;
  --font-size-md: 16px;
  --font-size-sm: 14px;
  --font-size-xs: 12px;
  --font-size-xxs: 10px;

  --font-line-height-xxl: 45px;
  --font-line-height-xl: 34px;
  --font-line-height-l: 28px;
  --font-line-height-md: 22px;
  --font-line-height-sm: 20px;
  --font-line-height-xs: 17px;
  --font-line-height-xxs: 16px;
  --font-line-height-xxxs: 15px;
  --font-line-height-xss: 12px;
}