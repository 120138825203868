.manyAvatarContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;

  & > div {
    margin-right: -4px;
  }
}

.oneAvatarContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}
