.container {
	width: 350px;

	margin-top: 25px;



	.button {
		margin-top: 5px;
	}
}

.place-and-time {
	display: block !important;
}