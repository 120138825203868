.divMessage {
	display: none;

	width: 100%;

	align-items: center;
	justify-content: center;

	animation: animation-message linear;
	animation-duration: 0.5s;
	animation-fill-mode: forwards;

	> p {
		height: 0;
		animation: animation-text linear;
		animation-duration: 0s;
		animation-fill-mode: forwards;
	}
}

@keyframes animation-message {
	from {
		height: 0px;
	}
	to {
		height: 40px;
	}
}

@keyframes animation-text {
	from {
		height: 0;
	}
	to {
		height: auto;
	}
}
