.button-primary-company {
	background: var(--color-primary-t15) !important;
	color: var(--color-primary-base) !important;
	border-color: transparent !important;

	.button-leftIcon,
	.button-rightIcon {
		color: var(--color-primary-base) !important;
	}

	&:hover {
		background: var(--color-primary-t30) !important;
	}

	&:focus {
		background: var(--color-primary-t15) !important;
		border-color: var(--color-primary-t45) !important;
	}

	&:disabled {
		background-color: transparent !important;
		.button-badge {
			border-color: var(--color-text-50) !important;
			> p {
				color: var(--color-text-50) !important;
			}
		}
	}
}

.button-secondary-company {
	background: transparent !important;
	color: var(--color-text-50) !important;
	border-color: transparent !important;

    padding-right: 20px !important;

    margin-bottom: 5px;

	.button-leftIcon,
	.button-rightIcon {
		color: var(--color-text-50) !important;
	}

	&:hover {
		background: var(--color-text-600) !important;
	}

	&:focus {
		background: var(--color-text-600) !important;
	}
}

.button-company-secondary {
	background-color: var(--color-primary-t45) !important;
	color: var(--color-primary-base) !important;

	.button-leftIcon,
	.button-rightIcon {
		color: var(--color-primary-base) !important;
	}
}

.button-company-primary {
	background-color: var(--color-primary-t45) !important;
	color: var(--color-primary-base) !important;

	.button-leftIcon,
	.button-rightIcon {
		color: var(--color-primary-base) !important;
	}
}