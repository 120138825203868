.collapse {
  background: var(--color-background-01) !important;
  border: 1px solid var(--color-background-04) !important;
  border-radius: 8px !important;
  margin-bottom: 25px !important;

  .panel {
    border: none !important;
    padding-left: 9px;

    > div:last-child {
      background: var(--color-background-01) !important;
      border-top: 1px solid var(--color-background-04) !important;
    }

    .body {
      margin-top: 25px;
      margin-bottom: 25px;
    }
  }
}

.rotate-true {
  animation: rotate-true 0.4s normal forwards;
}
.rotate-false {
  animation: rotate-false 0.4s normal forwards;
}

@keyframes rotate-true {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
@keyframes rotate-false {
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.div-color {
  position: absolute;

  width: 5px;
  height: 100%;

  left: 0;
  top: 0;
}

.brand {
  background: var(--color-primary-base) !important;
}
.default {
  background: var(--color-background-04) !important;
}
.success {
  background: var(--color-status-success-base) !important;
}
.error {
  background: var(--color-status-danger-base) !important;
}

.insideCard {
  border: none !important;
  margin-bottom: 0px !important;
  margin-top: -15px !important;

  .panel > div:last-child {
    border-top: none !important;
    margin-top: -25px !important;
  }
  .panel {
    padding-left: 0px !important;
    margin-left: -15px !important;
    
  }
  .Title {
    font-size: var(--font-size-xs);
    font-weight: var(--font-weight-light);
    color: var(--color-text-100) !important;
   
  }

  .rotate-true {
    color: var(--color-text-300) !important;
    font-size: 15px !important;
  }

  .rotate-false {
    color: var(--color-text-300) !important;
    font-size: 15px !important;
  }

  

  
}
