.container {
  position: relative;
}

.menuItem-with-children.item-open {
  border: 0.5px solid var(--color-background-04);
  border-radius: 8px;
}

.menuItem-children-active > div:first-child {
  transition-property: background-color;
  transition-delay: 0.4s;
  background: var(--color-primary-t15);
}

.menuItem {
  width: 100%;
  height: 45px;

  display: flex;
  align-items: center;

  cursor: pointer;
  user-select: none;
  border-radius: 8px;
  position: relative;

  &.active {
    background: var(--color-primary-t15);

    i {
      color: var(--color-primary-base) !important;
    }
  }

  &.disabled {
    background: none !important;

    i {
      color: var(--color-text-300) !important;
      opacity: 0.6;
    }

    .name-container {
      p {
        color: var(--color-text-300) !important;
        opacity: 0.6;
      }
    }
  }

  &:not(.active):hover {
    background: var(--color-text-600);

    i {
      color: var(--color-text-50) !important;
    }
  }

  .name-container {
    display: flex;
    align-items: center;

    .icon-holder {
      height: 4px;
      width: 4px;

      border-radius: 50%;

      background: var(--color-text-50);
    }
  }
}

.menuItem-not-collapsed {
  justify-content: space-between;
  padding: 16px;
}

.menuItem-collapsed {
  padding: 0;
  justify-content: center;

  &:hover {
    .name-container {
      p {
        color: var(--color-text-50) !important;
      }

      i {
        color: var(--color-text-50) !important;
      }
    }
  }
}

.expandable {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease;

  height: auto;
  background: var(--color-background-01);

  border-radius: 0 0 8px 8px;

  &.open {
    max-height: 500px;
    width: 100%;
  }

  &.close {
    max-height: 0;
  }

  > div:not(:last-child) > div {
    border-radius: 0 !important;
  }

  > div:last-child > div {
    border-radius: 0 0 8px 8px !important;
  }
}

.text {
  word-break: keep-all;
  min-width: 120px;
}

.show {
  opacity: 1;
  transition: opacity 0.1s;
  transition-delay: 0.2s;
}

.icon {
  position: absolute;
  top: 14.5px;
  right: 16px;
  z-index: 9999 !important;
}

.hidden {
  opacity: 0 !important;
}

.menuItem-with-children > .menuItem-open {
  border-radius: 8px 8px 0 0 !important;

  + div {
    > div {
      .menuItem {
        border-radius: 0 !important;
      }
    }
  }
}
