.container {
	width: 750px;
	height: auto;

	background: var(--color-background-01);
	border-radius: 5px;

	padding: 35px;

	> div {
		position: relative;
	}

	> div:last-child {
		margin-bottom: 0 !important;
	}

	.description {
		margin-top: 25px;
		margin-bottom: 25px;
	}
}

.margin-bottom {
	height: 15px;
}
