@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?y3vsea');
  src:  url('fonts/icomoon.eot?y3vsea#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?y3vsea') format('truetype'),
    url('fonts/icomoon.woff?y3vsea') format('woff'),
    url('fonts/icomoon.svg?y3vsea#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-pencil:before {
  content: "\e94a";
}
.icon-favorite:before {
  content: "\e94b";
}
.icon-Arrow-right:before {
  content: "\e900";
}
.icon-gear:before {
  content: "\e901";
}
.icon-exit:before {
  content: "\e902";
}
.icon-question-mark:before {
  content: "\e903";
}
.icon-delete-folder:before {
  content: "\e904";
}
.icon-download:before {
  content: "\e905";
}
.icon-email:before {
  content: "\e906";
}
.icon-exclamation-mark:before {
  content: "\e907";
}
.icon-exclamation-upside-down:before {
  content: "\e908";
}
.icon-like:before {
  content: "\e909";
}
.icon-lock:before {
  content: "\e90a";
}
.icon-logo:before {
  content: "\e90b";
}
.icon-logo-bootys:before {
  content: "\e90c";
}
.icon-menu-elipse:before {
  content: "\e90d";
}
.icon-plus:before {
  content: "\e90e";
}
.icon-search:before {
  content: "\e90f";
}
.icon-users-outline:before {
  content: "\e910";
}
.icon-Building:before {
  content: "\e911";
}
.icon-invoice-list:before {
  content: "\e912";
}
.icon-menu-ellipsis-horizontal:before {
  content: "\e913";
}
.icon-approve-tick:before {
  content: "\e914";
}
.icon-archive:before {
  content: "\e915";
}
.icon-attach-outline:before {
  content: "\e916";
}
.icon-bell:before {
  content: "\e917";
}
.icon-call:before {
  content: "\e918";
}
.icon-chevron-down:before {
  content: "\e919";
}
.icon-chevron-left:before {
  content: "\e91a";
}
.icon-chevron-right:before {
  content: "\e91b";
}
.icon-double-chevron-right:before {
  content: "\e91c";
}
.icon-close-x:before {
  content: "\e91d";
}
.icon-keyhole:before {
  content: "\e91e";
}
.icon-simple-cube:before {
  content: "\e91f";
}
.icon-simple-cubes:before {
  content: "\e920";
}
.icon-external-link:before {
  content: "\e921";
}
.icon-user-mark:before {
  content: "\e922";
}
.icon-logo-collapse:before {
  content: "\e923";
}
.icon-calculation:before {
  content: "\e924";
}
.icon-double-chevron-left:before {
  content: "\e925";
}
.icon-eye:before {
  content: "\e926";
}
.icon-close-eye:before {
  content: "\e927";
}
.icon-multimidia-equalizer:before {
  content: "\e928";
}
.icon-logo-bootys-login:before {
  content: "\e929";
}
.icon-logo-bootys-login-image:before {
  content: "\e92a";
}
.icon-arrow-down:before {
  content: "\e92b";
}
.icon-arrow-up:before {
  content: "\e92c";
}
.icon-search-action:before {
  content: "\e92d";
}
.icon-trash:before {
  content: "\e92e";
}
.icon-edit:before {
  content: "\e92f";
}
.icon-shopping-bag:before {
  content: "\e930";
}
.icon-setting:before {
  content: "\e931";
}
.icon-conical-flask:before {
  content: "\e932";
}
.icon-external-link1:before {
  content: "\e933";
}
.icon-remove-account:before {
  content: "\e934";
}
.icon-Box:before {
  content: "\e935";
}
.icon-calendar:before {
  content: "\e936";
}
.icon-invoice:before {
  content: "\e937";
}
.icon-copy:before {
  content: "\e938";
}
.icon-status-attention:before {
  content: "\e939";
}
.icon-bin-full:before {
  content: "\e93a";
}
.icon-buildings:before {
  content: "\e93b";
}
.icon-account:before {
  content: "\e93c";
}
.icon-document-info:before {
  content: "\e93d";
}
.icon-cube:before {
  content: "\e93e";
}
.icon-cubes:before {
  content: "\e93f";
}
.icon-connection:before {
  content: "\e940";
}
.icon-cash:before {
  content: "\e941";
}
.icon-document:before {
  content: "\e942";
}
.icon-clipboard:before {
  content: "\e943";
}
.icon-tag:before {
  content: "\e944";
}
.icon-tags:before {
  content: "\e945";
}
.icon-water-glass:before {
  content: "\e946";
}
.icon-library:before {
  content: "\e947";
}
.icon-basket:before {
  content: "\e948";
}
.icon-shipping-truck:before {
  content: "\e949";
}
