.adder-list > div > div > ul > li {
  border-block-end: none !important;

  padding: 5px 0 !important;

  > div {
    max-width: 80%;
    width: 100%;

    > div {
      max-width: none !important;

      > div {
        width: fit-content;
      }
    }
  }

  > ul {
    width: 150px;
    justify-content: flex-end;
    display: flex;
    > li {
      padding: 0 3px !important;
      > em {
        display: none;
      }
    }
  }
}

.collum {
  padding: 0 20% 12px 0 !important;
  margin: 0 0 12px 0 !important;

  border-bottom: 1px solid var(--color-background-04);

  > div {
    padding: 0 !important;
  }
}
