.ant-select-dropdown {
  background: var(--color-background-01) !important;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: var(--color-status-disabled-t15) !important;
}
.dropdown-is-loading {
  .ant-select-arrow {
    display: none !important;
  }
}
.bootys-dropdown.ant-select {
  min-height: 36px !important;
  height: auto !important;
  width: 100% !important;
  background: var(--color-background-01);
  border: 1px solid var(--color-background-06);
  border-radius: 8px;

  &:disabled {
    background-color: var(--color-status-disabled-t15) !important;
  }

  &::placeholder {
    color: var(--color-text-400) !important;
    font-size: var(--font-size-xs) !important;
    font-weight: var(--font-weight-light) !important;
    line-height: var(--font-line-height-xxs);
  }

  .ant-select-arrow {
    height: 16px !important;
    top: 16px !important;
    right: 12px !important;
  }

  .ant-select-selector {
    height: 100% !important;
    background-color: transparent !important;
    border: none !important;
    border-radius: 0px !important;
    box-shadow: none !important;
    font-family: var(--font-family) !important;
    color: var(--color-text-50);
    font-size: var(--font-size-xs) !important;
    font-weight: var(--font-weight-light) !important;

    .ant-select-selection-item {
      > div {
        > div:first-child {
          margin-right: 12px;
          font-size: var(--font-size-xs);
          > span {
            height: 24px !important;
            width: 24px !important;
            > span {
              > p {
                font-size: var(--font-size-xxs) !important;
                line-height: var(--font-line-height-xxxs) !important;
              }
            }
          }
        }
        > div:last-child {
          > p:first-child {
            font-size: var(--font-size-xs) !important;
            line-height: var(--font-line-height-xxxs);
          }
          > p:last-child:not(.text-input-simple-avatar) {
            display: none !important;
          }
        }
      }
    }

    .ant-select-selection-placeholder,
    .ant-select-selection-search,
    .ant-select-selection-item {
      line-height: 36px !important;
    }
  }
}

.ant-select-item-option-active:not(.dropdown-body-spinner):not(.dropdown-empty):not(.ant-select-item-option-selected) {
  background: var(--color-background-01) !important;
}

.bootys-dropdown.ant-select-focused {
  border: 1px solid var(--color-primary-base);
  box-shadow: none !important;
}

.ant-select-item-option-selected {
  background: var(--color-text-700) !important;
}

.ant-select-dropdown {
  padding: 0px !important;
  border-top: none !important;
  border-radius: 8px !important;

  .ant-select-item-option-content {
    display: flex;
    align-items: center;

    font-family: var(--font-family) !important;
    font-weight: var(--font-weight-light) !important;
    font-size: var(--font-size-xs) !important;
    line-height: var(--font-line-height-xs);
    color: var(--color-text-50);
  }

  .ant-select-item-option:not(.dropdown-empty):not(.dropdown-body-spinner) {
    min-height: 36px !important;
    height: auto !important;

    &:hover {
      background-color: var(--color-text-600) !important;
    }
  }
  .rc-virtual-list-scrollbar {
    width: 13px !important;

    justify-content: center !important;

    background: var(--color-background-01) !important;

    .rc-virtual-list-scrollbar-thumb {
      width: 3px !important;
      left: 5px !important;

      margin-top: 8px !important;
      margin-bottom: 8px !important;

      height: 112px !important;

      background: var(--color-text-600) !important;
    }
  }
}

.with-flag .ant-select-selection-search-input {
  margin-left: 20px !important;
}

.with-flag .ant-select-selection-placeholder {
  margin-left: 44px !important;
}

.ant-select-selection-placeholder {
  display: flex !important;
  align-items: center !important;
}

.ant-select-selection-item {
  width: inherit !important;
  display: flex !important;
  align-items: center !important;

  color: var(--color-text-50) !important;
  font-weight: var(--font-weight-light) !important;
  font-size: var(--font-size-xs) !important;
}

@keyframes load {
  from {
    left: -100%;
  }
  to {
    left: 100%;
  }
}

.dropdown-body-spinner,
.dropdown-empty {
  border-radius: 8px;
  background: var(--color-background-01) !important;
}

.dropdown-empty {
  display: flex !important;
  text-align: center !important;
  align-items: center !important;

  height: 38px;

  > div {
    color: var(--color-text-400) !important;
    margin-top: 0 !important;
  }
}

.dropdown-body-spinner {
  height: 42px;

  > div {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 0 !important;

    > div {
      margin: 0 !important;
    }
  }
}

.ant-select-clear {
  background: var(--color-background-01) !important;
  right: 14px !important;
  top: 17px !important;
  color: var(--color-text-300) !important;
}
