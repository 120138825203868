.container {
  padding: 5px 10px;
  display: flex;
  align-items: center;
  color: var(--color-text-50);
  margin: -10px;
  &:hover {
    background-color: var(--color-text-600);
    color: var(--color-text-50);
    width: fit-content;
    border-radius: 5px;
  }
}

.icon {
  margin-left: 5px;
  color: var(--color-text-50);
}
