:root {
  --font-family: 'Open Sans', sans-serif;
  overflow: hidden;

  //remove a cor do autofill
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    transition: background-color 5000s ease-in-out 0s,
      color 5000s ease-in-out 0s;
    background-color: var(--color-background-01) !important;
    color: var(--color-text-50) !important;
  }
}

body {
  font-family: var(--font-family) !important;
}
