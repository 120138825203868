.ant-select-dropdown {
  background: var(--color-background-01) !important;
}

.bootys-dropdown.ant-select-disabled {
  background-color: var(--color-status-disabled-t15) !important;
}

.bootys-dropdown.ant-select {
  height: 36px !important;
  width: 100% !important;
  background: var(--color-background-01);
  border: 1px solid var(--color-background-06);
  border-radius: 8px;

  &:disabled {
    background-color: var(--color-status-disabled-t15) !important;
  }

  &::placeholder {
    color: var(--color-text-400) !important;
    font-size: var(--font-size-xs) !important;
    font-weight: var(--font-weight-light) !important;
    line-height: var(--font-line-height-xxs);
  }

  .ant-select-selector {
    height: 100% !important;
    background-color: transparent !important;
    border: none !important;
    border-radius: 0px !important;
    box-shadow: none !important;
    font-family: var(--font-family) !important;
    color: var(--text-secondary);
    font-size: var(--font-size-xs) !important;
    font-weight: var(--font-weight-light) !important;

    &:disabled {
      background-color: var(--color-status-disabled-t15) !important;
    }

    .ant-select-selection-placeholder,
    .ant-select-selection-search,
    .ant-select-selection-item {
      line-height: 36px !important;
    }
  }
}

.ant-select-item-option-active:not(.dropdown-body-spinner):not(.dropdown-empty):not(.ant-select-item-option-selected) {
  background: var(--color-text-600) !important;
}

.bootys-dropdown.ant-select-focused {
  border: 1px solid var(--color-primary-base);
  box-shadow: none !important;
}

.ant-select-item-option-selected {
  background: var(--color-text-700) !important;
}

.ant-select-dropdown-placement-bottomLeft {
  padding: 0px !important;
  border-top: none !important;
  border-radius: 8px !important;

  .ant-select-item-option-content {
    font-family: var(--font-family) !important;
    font-weight: var(--font-weight-light) !important;
    font-size: var(--font-size-xs) !important;
    line-height: var(--font-line-height-xs);
  }

  .ant-select-item-option:not(.dropdown-empty):not(.dropdown-body-spinner) {
    height: 36px;

    &:hover {
      background-color: var(--color-text-600) !important;
    }
  }
  .rc-virtual-list-scrollbar {
    width: 13px !important;

    justify-content: center !important;

    .rc-virtual-list-scrollbar-thumb {
      width: 3px !important;
      left: 5px !important;

      margin-top: 8px !important;
      margin-bottom: 8px !important;

      height: 112px !important;
    }
  }
}

.with-flag .ant-select-selection-search-input {
  margin-left: 20px !important;
}

.with-flag .ant-select-selection-placeholder {
  margin-left: 44px !important;
}

.ant-select-selection-placeholder {
  display: flex !important;
  align-items: center !important;
}

.ant-select-selection-item {
  display: flex !important;
  align-items: center !important;

  color: var(--color-text-50) !important;
  font-weight: var(--font-weight-light) !important;
  font-size: var(--font-size-xs) !important;
}

@keyframes load {
  from {
    left: -100%;
  }
  to {
    left: 100%;
  }
}

.dropdown-body-spinner,
.dropdown-empty {
  border-radius: 8px;
  background: var(--color-background-01) !important;
}

.dropdown-empty {
  display: flex !important;
  text-align: center !important;
  align-items: center !important;

  height: 38px;

  > div {
    color: var(--color-text-400) !important;
    margin-top: 0 !important;
  }
}

.dropdown-body-spinner {
  height: 42px;

  > div {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 0 !important;

    > div {
      margin: 0 !important;
    }
  }
}

.ant-select-clear {
  background: var(--color-background-01) !important;
  right: 14px !important;
  top: 17px !important;
  color: var(--color-text-300) !important;
}
