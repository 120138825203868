.container {
  overflow: hidden;

  display: flex;
  .tag-container {
    display: flex;
    width: initial;
    overflow: hidden;

    .tag {
      cursor: default;
    }

    .tag-invisible {
      display: none;
    }
  }
}

.ellipse {
  height: 18px;
  width: 19px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 3px;

  background-color: var(--color-text-600);

  cursor: default;

  &:hover {
    background-color: var(--color-text-500);
  }
}

.row-selected {
  background: var(--color-primary-t15) !important;
  color: var(--color-primary-base) !important;
}
