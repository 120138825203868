.Tag {
  height: auto;
  min-height: 18px !important;

  display: flex !important;
  align-items: center !important;
  border: none !important;

  border-radius: 3px !important;

  background-color: transparent !important;

  &:not(.width-auto) {
    width: max-content;
  }
}

.width-auto {
  width: auto;
  > div {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
