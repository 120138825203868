.avatar {
    margin-bottom: 25px !important;
}

.footer-button {
    display: flex;
    margin-top: 5px !important;

    > button:first-child {
        margin-right: 10px;
    }
}

.container-change-password {
    width: 400px;
    padding: 20px;
}
