.ant-tooltip-inner {
  background-color: var(--color-text-50) !important;
  color: var(--color-text-800) !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  font-family: var(--font-family) !important;
  color: white !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  min-height: 16px !important;
  height: 24px !important;
}

.ant-tooltip-arrow {
  --antd-arrow-background-color: var(--color-text-50) !important;
  .ant-tooltip-arrow-content {
    background-color: var(--color-text-50) !important;
  }
}

.tooltip-is-copied {
  .ant-tooltip-inner {
    background-color: var(--color-status-success-base) !important;
  }

  .ant-tooltip-arrow {
    .ant-tooltip-arrow-content {
      background-color: var(--color-status-success-base) !important;
    }
  }
}

.ant-popover-inner {
  padding: 0 !important;
}
