.ant-table-thead {
  height: 45px;

  > tr {
    > th:first-child {
      border-radius: 8px 0 0 0 !important;
    }
    > th:last-child {
      border-radius: 0 8px 0 0 !important;
    }
  }

  th.ant-table-cell {
    background: var(--color-background-04) !important;
    border: none !important;

    font-family: var(--font-family) !important;
    font-style: normal;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-xs);
    line-height: var(--font-line-height-sm);
    color: var(--color-text-50) !important;

    &::before {
      display: none !important;
    }
  }
}

.ant-popover-inner-content {
  padding: 0 !important;
}
.ant-popover-inner {
  border-radius: 8px;
}

.not-pagination {
  .ant-table-tbody {
    > tr:last-child {
      > td:first-child {
        border-radius: 0 0 0 8px !important;
      }
      > td:last-child {
        border-radius: 0 0 8px 0 !important;
      }
    }
  }
}

.ant-table-tbody {
  .ant-empty-description {
    color: var(--color-text-600) !important;
  }
  .ant-empty-img-simple {
    stroke: var(--color-text-600) !important;
    .ant-empty-img-simple-ellipse {
      fill: transparent !important;
    }
    .ant-empty-img-simple-g {
      stroke: var(--color-text-600) !important;
      > path:last-child {
        fill: var(--color-text-600) !important;
      }
    }
  }
  > tr.ant-table-placeholder:hover > td {
    background: var(--color-background-01) !important;
  }
  .ant-table-row-selected {
    .ant-table-cell {
      background: var(--color-primary-t15) !important;
    }
  }
  .ant-table-row:not(.ant-table-row-selected) {
    &:hover {
      .ant-table-cell {
        background: var(--color-background-02) !important;
      }
    }

    .ant-table-cell {
      background: var(--color-background-01) !important;
    }
  }
  .ant-table-row {
    height: 48px;

    &:hover {
      .action {
        opacity: 1 !important;
      }
    }
    .ant-table-cell:first-child {
      border-left: 1px solid var(--color-background-04) !important;
    }

    .ant-table-cell:last-child {
      border-right: 1px solid var(--color-background-04) !important;
    }
    .ant-table-cell {
      font-family: var(--font-family) !important;
      font-style: normal;
      font-weight: var(--font-weight-light);
      font-size: var(--font-size-xs);
      line-height: var(--font-line-height-sm);
      color: var(--color-text-50) !important;

      padding-top: 0 !important;
      padding-bottom: 0 !important;

      border-bottom: 1px solid var(--color-background-04) !important;

      > p {
        margin: 0 !important;
      }
    }
  }
  > tr > td {
    border-bottom: 1px solid var(--color-background-04) !important;
  }
}

.action {
  opacity: 0;
  width: 100%;
  cursor: pointer;
  transition: opacity 0.5s ease-in-out;
  border-radius: 8px;
  padding: 0 !important;
}

.ant-popover-arrow {
  display: none !important;
}

.actions-container {
  min-width: 150px;
  border-radius: 8px !important;
  border: 1px solid var(--color-background-01);
  margin-top: -10px;

  > div:first-child {
    border-radius: 8px 8px 0 0 !important;
  }
  > div:last-child {
    border-radius: 0 0 8px 8px !important;
  }

  .action-item {
    width: 100%;
    height: 36px;
    display: flex;
    align-items: center;
    padding-left: 8px;
    cursor: pointer;
    user-select: none;

    &:not(:last-child) {
      border-bottom: 1px solid var(--color-background-01);
    }

    &:hover {
      background: var(--color-background-02) !important;
    }

    p {
      margin: 0px;

      font-family: var(--font-family) !important;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;

      color: var(--color-text-50);
    }
  }
}

.status {
  height: 25px;
  width: fit-content;

  padding: 0px 10px;
  border-radius: 5px;

  display: flex;
  align-items: center;

  > p {
    margin: 0 !important;
  }
}

.table-status-active {
  background: rgba(92, 198, 137, 0.15);

  color: var(--color-status-success-base) !important;
}
.table-status-disabled {
  background: rgba(189, 189, 189, 0.15);

  color: var(--color-status-disabled-base) !important;
}
.table-status-invalid {
  background: rgba(238, 82, 82, 0.15);

  color: var(--color-status-danger-base) !important;
}
.table-status-pending {
  background: rgba(253, 172, 65, 0.15);

  color: var(--color-status-warning-base) !important;
}
.ant-table-column-sort {
  &:hover {
    .ant-table-column-sorter {
      background: rgba(0, 0, 0, 0.1) !important;
    }
  }
}
.ant-table-column-sorter {
  margin: 0 !important;
  margin-left: 5px !important;

  border-radius: 50px !important;

  width: 26px;
  height: 26px;

  display: flex;
  justify-content: center;
  align-items: center;
  .ant-table-column-sorter-inner {
    display: inherit;
    .ant-table-column-sorter-up,
    .ant-table-column-sorter-down {
      > svg {
        display: none;
      }
    }

    .ant-table-column-sorter-up + .ant-table-column-sorter-down {
      padding-top: 0px !important;
    }
    .ant-table-column-sorter-down {
      margin-top: 0 !important;
    }

    .ant-table-column-sorter-up {
      margin-top: 2px;
    }

    .ant-table-column-sorter-up.active {
      &::after {
        font-family: 'icomoon' !important;
        content: '\e92b' !important;
        color: var(--color-text-50) !important;
        font-size: var(--font-size-sm) !important;
      }
    }
    .ant-table-column-sorter-down.active {
      &::after {
        font-family: 'icomoon' !important;
        content: '\e92c' !important;
        color: var(--color-text-50) !important;
        font-size: var(--font-size-sm) !important;
      }
    }
  }
}

.ant-table {
  background: transparent !important;
}

.table-last-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-table-column-sorters {
  justify-content: start !important;
}

.ant-table-thead {
  .ant-table-column-title,
  .ant-table-cell {
    flex: none !important;
    width: fit-content;
    font-weight: var(--font-weight-bold) !important;
    font-size: var(--font-size-xs) !important;
    line-height: var(--font-line-height-xs) !important;
    color: var(--color-text-50) !important;
  }
}

.ant-table-selection-column,
.ant-table-cell-row-hover {
  border-radius: 0 !important;
}
