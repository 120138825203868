.content-avatar {
  display: flex;
  width: fit-content;

  .avatar {
    user-select: none;
    display: flex !important;
    align-items: center;
  }

  .content-description {
    display: flex;
    align-items: center;
  }

  .avatar-with-description {
    margin-right: 10px;
  }
}

.avatar-with-link {
  cursor: pointer;
}

.SS {
  height: 16px !important;
  width: 16px !important;
  font-size: 16px !important;
}

.S {
  height: 24px !important;
  width: 24px !important;
  font-size: 24px !important;
}

.SM {
  height: 28px !important;
  width: 28px !important;
  font-size: 28px !important;
}

.M {
  height: 32px !important;
  width: 32px !important;
  font-size: 32px !important;
}

.LM {
  height: 40px !important;
  width: 40px !important;
  font-size: 40px !important;
}

.L {
  height: 48px !important;
  width: 48px !important;
  font-size: 48px !important;
}

.XL {
  height: 56px !important;
  width: 56px !important;
  font-size: 56px !important;
}

.XXL {
  height: 72px !important;
  width: 72px !important;
  font-size: 72px !important;
}
