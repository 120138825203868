.ant-radio-inner {
  width: 16px !important;
  height: 16px !important;
  border-color: var(--color-text-300) !important;
  background-color: var(--color-background-01) !important;

  &:after{
    top: 5px !important;
    left: 5px !important;
  }
}

.ant-radio:not(.ant-radio-checked):not(.ant-radio-disabled) {
  &:hover {
    .ant-radio-inner {
      border-color: var(--color-text-100) !important;
    }
  }
}

.ant-radio-inner::after {
  background-color: var(--color-primary-base) !important;
  border-color: var(--color-primary-base) !important;
}

.ant-radio-checked .ant-radio-inner {
  border: 6px solid var(--color-primary-base) !important;
  background-color: var(--color-white) !important;

  &:hover {
    color: var(--color-primary-base) !important;
    border: var(--color-primary-base) !important;
  }

  &:after{
    display: none;
    
    background-color: var(--color-primary-base) !important;
    border-color: var(--color-primary-base) !important;
  }
}

.ant-radio-disabled .ant-radio-inner {
  border-color: var(--color-text-400) !important;
  background-color: var(--color-status-disabled-t15) !important;

  &:hover {
		border-color: var(--color-text-400) !important;
  }

  &:after{
		border-color: var(--color-status-disabled-t15) !important;
  }
}

.ant-radio-wrapper-checked .ant-radio-disabled .ant-radio-inner{
  background-color: var(--color-status-disabled-t15) !important;
  border-color: var(--color-text-400) !important;
}

.ant-radio-wrapper {
	height: 16px !important;
	font-size: 0 !important;
}

