.Page {
	display: flex;

	width: 100%;
	height: 100%;

	justify-content: center;

	overflow: auto;

	background: var(--color-background-00);

	padding-top: 103px;

	overflow: auto;
	.rightBar {
		margin-left: 25px;

		position: relative;

		.bread-crumb {
			position: absolute;
			top: -103px;
		}
	}
}
