.margin-bottom {
	margin-bottom: 20px;
}
.Container:not(.margin-bottom) {
	margin-bottom: 0px;
}
.Container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;

	.input-container {
		position: relative;
		min-width: 100px;
		width: 100%;

		.Icon {
			position: absolute;
			right: 12px;
			top: 10px;
			z-index: 9999;
		}

		.errorText {
			margin-top: 6px;
			font-family: var(--font-family);
			font-weight: var(--font-weight-light);
			font-size: var(--font-size-xs);
			line-height: var(--font-line-height-xxs);
			color: var(--color-status-danger-base);
		}

		.Left-Icon {
			position: absolute;
			left: 12px;
			top: 10px;
			z-index: 9999;
		}

		.TextInput {
			height: 36px !important;
			background-color: var(--color-background-01);
			box-shadow: none !important;
			border: 1px solid var(--color-background-06) !important;
			border-radius: 8px !important;

			font-family: var(--font-family) !important;
			font-size: var(--font-size-xs) !important;
			line-height: var(--font-line-height-xxs) !important;
			font-weight: normal;
			color: var(--color-text-50) !important;

			&::placeholder {
				color: var(--color-text-400) !important;
			}

			&:focus {
				border: 1px solid var(--color-text-400) !important;
			}

			&:disabled {
				background-color: var(--color-status-disabled-t15) !important;
				border: 1px solid var(--color-status-disabled-t45) !important;
			}
		}
	}

	.error {
		.TextInput {
			border: 1px solid var(--color-status-danger-base) !important;

			&:hover {
				border: 1px solid var(--color-status-danger-base) !important;
			}

			&:focus {
				border: 1px solid var(--color-status-danger-base) !important;
			}
		}
	}

	.success {
		.TextInput {
			border: 1px solid var(--color-status-success-base) !important;

			&:hover {
				border: 1px solid var(--color-status-success-base) !important;
			}

			&:focus {
				border: 1px solid var(--color-status-success-base) !important;
			}
		}
	}

	.with-left-icon {
		.TextInput {
			padding-left: 40px;
		}
	}

	.with-right-icon {
		.TextInput {
			padding-right: 32px;
		}
	}

	.div-label {
		display: flex;
		.label {
			margin-right: 3px;
		}
	}

	.loading {
		position: absolute;
		top: 8px;
		right: 0;
	}
}
