.content-permission-wrappe {
  cursor: not-allowed;
  margin-right: 42px;
  > button {
    cursor: not-allowed;
    user-select: none;
    pointer-events: none;

    color: var(--color-text-300) !important;

    > div:first-child > i {
      cursor: not-allowed;
      color: var(--color-text-300) !important;
    }
  }
}
