.collapse {
    .ant-collapse-item {
        border-bottom: 1px solid var(--color-background-04);

        .ant-collapse-header {
            padding: 0px !important;
            height: 56px !important;
        }
    }

    .ant-collapse-item-active {
        border: none !important;
    }

    .ant-collapse-content {
        margin: 0px;

        & > div .header {
            margin-left: 25px !important;
        }

        .ant-collapse-content {
            & > div .header {
                margin-left: 50px !important;
            }

            .ant-collapse-content {
                & > div .header {
                    margin-left: 75px !important;
                }

                .ant-collapse-content {
                    & > div .header {
                        margin-left: 100px !important;
                    }
                }

                .ant-collapse-content {
                    & > div .header {
                        margin-left: 125px !important;
                    }
                }
            }
        }
    }
}
