.margin-bottom {
  margin-bottom: 20px;
}
.divDropdown:not(.margin-bottom) {
  margin-bottom: 0px;
}

.errorText {
  margin-top: 6px;
  font-family: var(--font-family) !important;
  font-weight: normal;
  font-size: var(--font-size-xs);
  line-height: var(--font-line-height-xxs);
  color: var(--color-status-danger-base) !important;
}

.spinner {
  position: absolute !important;
  top: 10px !important;
  right: 12px !important;
  z-index: 5;

  > div {
    margin: 0 !important;
  }
}

.option {
  font-family: var(--font-family) !important;
  font-style: normal !important;
  font-weight: var(--font-weight-light) !important;
  font-size: var(--font-size-xs) !important;
  background-color: var(--color-background-01);
}

.option-avatar {
  height: 43px !important;
  padding: 0 16px !important;

  .div-avatar {
    margin-left: 12px;
  }
}

.avatarOpt {
  padding-top: 3px !important;
}

.borderRed {
  border: 1px solid var(--color-status-danger-base) !important;
}

.divDropdown {
  width: 100%;
  position: relative;

  .div-flag {
    width: 28px;
    height: 21px;
    border-radius: 12px;
    background: var(--color-text-600);
    border: 2px solid var(--color-text-600);

    position: absolute;
    top: 8px;
    left: 12px;
    z-index: 1;
  }
}

.divContent,
.divContentWithCode {
  align-items: center;
  overflow-y: auto;
  width: 100%;

  > p {
    color: var(--color-text-50) !important;
    font-weight: var(--font-weight-light) !important;
    font-size: var(--font-size-xs) !important;
    max-height: 30px;
  }

  > i {
    margin-right: 13px;
  }
}

.divContent {
  display: flex;
  > div {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.divContentWithCode {
  display: block;
}

.div-label {
  display: flex;
  .label {
    margin-right: 3px;
  }
}

.icon-with-tooltip {
  margin-left: 5px;
}
