.breadCrumb {
  display: flex;
  align-items: center;
  height: 19px;
  user-select: none;
  > span {
    display: flex;
  }
  > ol > li {
    display: flex;
    align-items: center;
  }
}

.icon {
  cursor: default;
}

.title {
  margin-bottom: 5px;
}
