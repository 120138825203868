.margin-bottom {
  margin-bottom: 18px;
}
.Container:not(.margin-bottom) {
  margin-bottom: 0px;
}
.Container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  min-width: 100px;
  width: 100%;

  .icon-with-tooltip {
    margin-left: 5px;
  }

  .tag {
    > p > p {
      font-size: var(--font-size-xxs) !important;
      font-weight: var(--font-weight-bold) !important;
    }
  }

  .input-container {
    width: 100%;
    position: relative;
    border-radius: 8px;

    .spinner,
    .empyt {
      height: 36px;
      position: absolute;
      right: 50%;

      width: 0 !important;

      display: flex;
      align-items: center;
      justify-content: center;

      > div {
        z-index: 99999 !important;
        margin: 0 !important;
      }
    }
  }

  .Icon,
  .search {
    position: absolute;
    top: 10px;
  }

  .Icon {
    right: 12px;
  }

  .search {
    left: 12px;
  }

  .errorText {
    margin-top: 5px;
    margin-bottom: 0 !important;
    font-family: var(--font-family);
    font-weight: var(--font-weight-light);
    font-size: var(--font-size-xs);
    line-height: var(--font-line-height-xxs);
    color: var(--color-status-danger-base);
  }

  .Left-Icon {
    position: absolute;
    left: 12px;
    top: 10px;
    z-index: 9999;
  }

  .TextInput {
    display: flex;
    align-items: center;
    height: 36px !important;
    background-color: var(--color-background-01);
    box-shadow: none !important;
    border: 1px solid var(--color-background-06) !important;
    border-radius: 8px !important;
    width: 100%;

    font-family: var(--font-family) !important;
    font-size: var(--font-size-xs) !important;
    line-height: var(--font-line-height-xxs) !important;
    font-weight: normal;
    color: var(--color-text-50) !important;

    > div {
      border: none !important;
      background-color: transparent !important;
      width: 100%;
      box-shadow: none !important;
      padding: 0 12px 0 40px !important;
    }

    &:focus-within {
      border-color: var(--color-primary-base) !important;
      ~ i {
        color: var(--color-primary-base) !important;
      }
    }
  }

  .TextInput-without-searchIcon > div {
    padding-left: 12px !important;
    > span:first-child > input {
      padding-left: 0 !important;
    }
  }

  .error {
    .TextInput {
      border: 1px solid var(--color-status-danger-base) !important;

      &:hover {
        border: 1px solid var(--color-status-danger-base) !important;
      }

      &:focus {
        border: 1px solid var(--color-status-danger-base) !important;
      }
    }
  }

  .success {
    .TextInput {
      border: 1px solid var(--color-status-success-base) !important;

      &:hover {
        border: 1px solid var(--color-status-success-base) !important;
      }

      &:focus {
        border: 1px solid var(--color-status-success-base) !important;
      }
    }
  }

  .with-left-icon {
    .TextInput {
      padding-left: 40px;
    }
  }

  .with-right-icon {
    .TextInput {
      padding-right: 32px;
      > span {
        display: none !important;
      }
    }
  }

  .disabled {
    > div {
      background-color: var(--color-status-disabled-t15) !important;
      border: 1px solid var(--color-status-disabled-t45) !important;
      cursor: not-allowed !important;
    }

    .Left-Icon {
      color: var(--color-text-400) !important;
      cursor: no-drop !important;
    }
  }

  .div-label {
    display: flex;
    .label {
      margin-right: 3px;
    }
  }
}

.chevron-down {
  animation: rotate-down 0.4s normal forwards;
}

.chevron-up {
  animation: rotate-up 0.4s normal forwards;
}

@keyframes rotate-down {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

@keyframes rotate-up {
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.option {
  font-family: var(--font-family) !important;
  font-style: normal !important;
  font-weight: var(--font-weight-light) !important;
  font-size: var(--font-size-xs) !important;
  background-color: var(--color-background-01);
}

.option-avatar {
  height: 43px !important;
  padding: 0 16px !important;
}
.div-avatar {
  margin-left: 12px;
}
.divContent,
.divContentWithSubLabel {
  align-items: center;
  > p {
    color: var(--color-text-50) !important;
    font-weight: var(--font-weight-light) !important;
    font-size: var(--font-size-xs) !important;
  }

  > i {
    margin-right: 13px;
  }
}

.divContent {
  display: flex;
}

.divContentWithSubLabel {
  display: block;
}
