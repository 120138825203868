.container {
  padding: 20px;
  max-width: 400px;

  .permissionContainer {
    width: 100%;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
    border: 1px solid var(--color-background-04);
    border-radius: 12px;
  }
}
