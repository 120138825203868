html {
  // Primary
  --color-primary-darkest: #3f3798;
  --color-primary-dark: #4f44c6;
  --color-primary-base: #5e50f2;
  --color-primary-light: #7e73f5;
  --color-primary-lightest: #9e96f7;
  --color-primary-t45: rgba(94, 80, 242, 0.45);
  --color-primary-t30: rgba(94, 80, 242, 0.35);
  --color-primary-t15: rgba(94, 80, 242, 0.15);

  // Constant
  --color-black: #121212;
  --color-white: #ffffff;

  // Text
  --color-text-50: #333333;
  --color-text-100: #414141;
  --color-text-200: #717171;
  --color-text-300: #a0a0a0;
  --color-text-400: #c4c4c4;
  --color-text-500: #d0d0d0;
  --color-text-600: #e7e7e7;
  --color-text-700: #f3f3f3;
  --color-text-800: #ffffff;

  // Background
  --color-background-00: #f7f7f7;
  --color-background-01: #ffffff;
  --color-background-02: #f7f7f7;
  --color-background-03: #f3f3f3;
  --color-background-04: #eaeaea;
  --color-background-06: #e5e5e5;
  --color-background-08: #e0e0e0;
  --color-background-12: #dcdcdc;
  --color-background-16: #d7d7d7;
  --color-background-24: #d2d2d2;

  // Status
  --color-status-success--40: #3e7e59;
  --color-status-success--20: #4ea272;
  --color-status-success-base: #5cc689;
  --color-status-success-P20: #7dd1a1;
  --color-status-success-P40: #9dddb8;
  --color-status-success-t45: rgba(92, 198, 137, 0.45);
  --color-status-success-t30: rgba(92, 198, 137, 0.3);
  --color-status-success-t15: rgba(92, 198, 137, 0.15);

  --color-status-warning--40: #9f6e2e;
  --color-status-warning--20: #ce8e38;
  --color-status-warning-base: #fdac41;
  --color-status-warning-P20: #fdbd67;
  --color-status-warning-P40: #fecd8d;
  --color-status-warning-t45: rgba(253, 172, 65, 0.45);
  --color-status-warning-t30: rgba(253, 172, 65, 0.3);
  --color-status-warning-t15: rgba(253, 172, 65, 0.15);

  --color-status-danger--40: #963838;
  --color-status-danger--20: #c24646;
  --color-status-danger-base: #ee5252;
  --color-status-danger-P20: #f17575;
  --color-status-danger-P40: #f59797;
  --color-status-danger-t45: rgba(238, 82, 82, 0.45);
  --color-status-danger-t30: rgba(238, 82, 82, 0.3);
  --color-status-danger-t15: rgba(238, 82, 82, 0.15);

  --color-status-information--40: #326381;
  --color-status-information--20: #3e7fa7;
  --color-status-information-base: #489acc;
  --color-status-information-P20: #6daed6;
  --color-status-information-P40: #91c2e0;
  --color-status-information-t45: rgba(72, 154, 204, 0.45);
  --color-status-information-t30: rgba(72, 154, 204, 0.3);
  --color-status-information-t15: rgba(72, 154, 204, 0.15);

  --color-status-default--40: #262626;
  --color-status-default--20: #2d2d2d;
  --color-status-default-base: #333333;
  --color-status-default-P20: #5c5c5c;
  --color-status-default-P40: #858585;
  --color-status-default-t45: rgba(51, 51, 51, 0.45);
  --color-status-default-t30: rgba(51, 51, 51, 0.3);
  --color-status-default-t15: rgba(51, 51, 51, 0.15);

  --color-status-disabled--40: #848484;
  --color-status-disabled--20: #aaaaaa;
  --color-status-disabled-base: #d0d0d0;
  --color-status-disabled-P20: #d9d9d9;
  --color-status-disabled-P40: #e3e3e3;
  --color-status-disabled-t45: rgba(208, 208, 208, 0.45);
  --color-status-disabled-t30: rgba(208, 208, 208, 0.3);
  --color-status-disabled-t15: rgba(208, 208, 208, 0.15);

  --color-status-primary--40: #3f3798;
  --color-status-primary--20: #4f44c6;
  --color-status-primary-base: #5e50f2;
  --color-status-primary-P20: #7e73f5;
  --color-status-primary-P40: #9e96f7;
  --color-status-primary-t45: rgba(94, 80, 242, 0.45);
  --color-status-primary-t30: rgba(94, 80, 242, 0.35);
  --color-status-primary-t15: rgba(94, 80, 242, 0.15);

  // Users
  --color-users01: #4bd0f9;
  --color-users02: #0077b6;
  --color-users03: #0353a4;
  --color-users04: #662e9b;
  --color-users05: #d100d1;
  --color-users06: #ee4266;
  --color-users07: #99582a;
  --color-users08: #f35b04;
  --color-users09: #ffa800;
  --color-users10: #f6d44a;
  --color-users11: #3ab27a;
  --color-users12: #06d6a0;
  --color-users13: #34a0a4;
  --color-users14: #00d8d5;

  // Highlight
  --color-highlight: rgba(255, 255, 0, 0.45);
}
