.footer-button {
	display: flex;

	> button:first-child {
		margin-right: 10px;
	}
}

.avatar-list {
	display: flex;

	> div:first-child {
		margin-right: 10px;
	}
}