.ant-tabs-top {
  margin-bottom: 14px !important;
  .ant-tabs-nav {
    margin: 0 !important;
    height: 42px !important;
    width: 100% !important;
    border-radius: 8px !important;
    border: 1px solid var(--color-background-04) !important;
    background: var(--color-background-01) !important;
    &::before {
      border-bottom: none !important;
    }

    .ant-tabs-nav-wrap {
      border-radius: 8px !important;
    }
    .ant-tabs-nav-list {
      .ant-tabs-ink-bar {
        background: var(--color-primary-base) !important;
        border-radius: 2px 2px 0px 0px !important;
      }
      .ant-tabs-tab {
        border-bottom: 2px solid transparent !important;
        margin-left: 19px !important;
        margin-right: 19px !important;

        &:first-child {
          margin-left: 31px !important;
        }
        &:nth-last-child(2) {
          margin-right: 31px !important;
        }

        .ant-tabs-tab-btn {
          font-size: var(--font-size-xs) !important;
          line-height: var(--font-line-height-xxxs) !important;
          font-weight: var(--font-weight-bold) !important;
          user-select: none !important;
        }

        &:not(.ant-tabs-tab-active) {
          .ant-tabs-tab-btn {
            font-weight: var(--font-weight-normal) !important;
          }
          &:hover {
            border-bottom-color: var(--color-text-600) !important;
            color: var(--color-primary-base) !important;
          }
        }
      }

      .ant-tabs-tab.ant-tabs-tab-disabled {
        color: none !important;
        font-weight: var(--font-weight-normal) !important;
        &:hover {
          border-bottom-color: var(--color-text-600) !important;
          color: var(--color-status-disabled-base) !important;
        }
      }

      .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: var(--color-primary-base) !important;
        font-weight: var(--font-weight-bold) !important;
      }
    }
  }
}

.button-horizontal-tab {
  position: absolute;
  top: 0;
  right: 0;
}

.ant-dropdown-menu-vertical {
  background: var(--color-background-01) !important;

  .ant-dropdown-menu-item:hover {
    background: var(--color-text-600) !important;
  }
}
