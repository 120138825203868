.ManyItemsBody {
  width: 400px;
  padding: 20px;

  ul {
    margin-top: 20px;
    margin-bottom: 20px;

    list-style: none;

    padding: 0;

    li {
      display: flex;
      align-items: center;

      &::before {
        content: '•';
        color: var(--color-text-50);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 15px;
      }
    }
  }
}

.avatar-center {
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.manyAvatarContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;

  & > div {
    margin-right: -4px;
    width: 28px;
  }
}

.deleteOkBtn {
  background-color: var(--color-status-danger-base) !important;

  &:hover {
    background-color: var(--color-status-danger-base) !important;
  }

  &:active {
    background-color: var(--color-status-danger-base) !important;
  }

  &:focus {
    background-color: var(--color-status-danger-base) !important;
  }
}

.body-container {
  min-width: 400px;

  ul {
    margin-top: 20px;
    margin-bottom: 20px;

    list-style: none;

    padding: 0;

    li {
      display: flex;
      align-items: center;

      &::before {
        content: '•';
        color: var(--color-text-50);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 15px;
      }
    }
  }
}
