.container {
	display: flex;

	align-items: center;
	justify-content: center;

	width: 24px;
	height: 24px;

	border-radius: 50%;
	background: var(--color-primary-base);
}
