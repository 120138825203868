.margin-bottom {
  margin-bottom: 20px;
}
.Container:not(.margin-bottom) {
  margin-bottom: 0px;
}

.error {
  border: 1px solid var(--color-status-danger-base) !important;
}

.success {
  border: 1px solid var(--color-status-success-base) !important;
}

.brand {
  border: 1px solid var(--color-primary-base) !important;
}

.disabled {
  color: var(--color-text-400) !important;
  cursor: no-drop !important;
}

.Container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border: 1px solid var(--color-background-04);
  background-color: var(--color-background-02);
  border-radius: 8px;
  height: 36px;

  .input-container {
    position: relative;
    width: 100%;
    height: 100%;

    .errorText {
      margin-top: 5px;
      margin-bottom: 0 !important;
      font-family: var(--font-family);
      font-weight: var(--font-weight-light);
      font-size: var(--font-size-xs);
      line-height: var(--font-line-height-xxs);
      color: var(--color-status-danger-base);
    }

    .Right-Icon-arrow,
    .Left-Icon-arrow {
      position: absolute;
      z-index: 0;
      height: 6px !important;
      pointer-events: none !important;
    }

    .Right-Icon-arrow {
      right: 2px;
      top: 15px;
    }

    .Left-Icon-arrow {
      left: 27px;
      top: 15px;
    }

    .Right-Icon,
    .Left-Icon,
    .Left-title-string,
    .Right-title-string {
      position: absolute;
      z-index: 0;
      height: 6px !important;
      width: 9px !important;
    }

    .Left-title-string,
    .Right-title-string {
      top: 12px;
    }

    .Left-Icon,
    .Right-Icon {
      top: 15px;
    }

    .Right-Icon,
    .Right-title-string {
      right: 12px;
    }

    .Left-Icon,
    .Left-title-string {
      left: 12px;
    }
  }
  .right-icon {
    .Right-Icon,
    .Right-title-string {
      right: 60px;
    }
  }
  .left-icon {
    .Left-Icon,
    .Left-title-string {
      left: 60px;
    }
  }
}
