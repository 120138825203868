.CollapseList {
    width: 100%;

    .collapse {
        height: 57px !important;
        padding: 0px !important;

        .ant-collapse-header {
            padding: 0px !important;
        }
    }
}
