.primary-tag {
	background: var(--color-primary-base) !important;
}
.secondary-tag {
	background: var(--color-text-600) !important;
}
.tertiary-tag {
	background: var(--color-status-disabled-t15) !important;
}

.primary-tag {
	.icon-x-tag {
		color: var(--color-white) !important;
	}
}
.secondary-tag {
	.icon-x-tag {
		color: var(--color-status-default-base) !important;
	}
}
.tertiary-tag {
	.icon-x-tag {
		color: var(--color-status-disabled-base) !important;
	}
}
.link-primary-tag {
	border: 1px solid var(--color-primary-base) !important;
	background: transparent !important;
	.icon-x-tag {
		color: var(--color-primary-base) !important;
	}
	> p {
		color: var(--color-primary-base) !important;
	}
}
.link-secondary-tag {
	border: 1px solid var(--color-status-default-base) !important;
	background: transparent;
	.icon-x-tag {
		color: var(--color-status-default-base) !important;
	}
	> p {
		color: var(--color-status-default-base) !important;
	}
}
.link-tertiary-tag {
	border: 1px solid var(---color-status-disabled-base) !important;
	background: transparent;
	.icon-x-tag {
		color: var(---color-status-disabled-base) !important;
	}
	> p {
		color: var(---color-status-disabled-base) !important;
	}
}

.Tag-with-close-x {
	padding-left: 5px;
	padding-right: 2px !important;
}

.Tag-without-close-x {
	padding: 0 5px;
}

.icon-x-tag {
	margin: 0 2px !important;
}
