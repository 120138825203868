.PhoneInput--focus {
	.PhoneInputCountry {
		border-top-left-radius: 7px;
		border-bottom-left-radius: 7px;
		background: var(--color-background-04) !important;
	}
}
.PhoneInput {
	.PhoneInputInput {
		width: 100% !important;
		height: 100% !important;
		border-radius: 0 8px 8px 0 !important;
	}
	.PhoneInputCountry {
		border-right: 1px solid var(--color-background-06) !important;
		.PhoneInputCountryIcon {
			width: 28px !important;
			height: 20px !important;
			border: 1px solid var(--color-text-600) !important;
			border-radius: 12px;
			box-shadow: unset !important;
			margin-left: 9px !important;
			> img {
				border-radius: 12px;
			}
		}
		.PhoneInputCountrySelectArrow {
			width: 4px !important;
			height: 4px !important;
			margin-right: 10px !important;
			color: var(--color-text-400) !important;
		}
	}

	&:focus {
		.PhoneInputCountry {
			background: var(--color-background-04) !important;
			.PhoneInputCountrySelectArrow {
				color: var(--color-text-400) !important;
			}
		}
	}
}

.ant-dropdown {
	max-height: 400px;
	overflow: auto;
	border-radius: 8px;

	&::-webkit-scrollbar {
		border-radius: 8px !important;
	}

	.ant-dropdown-menu {
		background: var(--color-background-01) !important;
		> li {
			color: var(--color-text-50) !important;
			&:hover {
				background: var(--color-text-600) !important;
			}
		}

	}
}
