.status {
  height: 24px !important;
  width: fit-content;

  display: flex;
  align-items: center;

  border-radius: 8px !important;

  padding: 0 10px !important;

  border: none !important;
}
