.divCheckbox {
  display: flex;
  align-items: center;

  .label {
    user-select: none;
    cursor: pointer;
    color: var(--color-text-50);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-xs);
    margin-left: 10px;
  }

  .disabled {
    pointer-events: block;
    cursor: no-drop;
  }

  div {
    margin-left: 10px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .icon-status {
    margin-left: 6px;
    &::before {
      font-family: 'icomoon' !important;
      content: '\e939';
      color: var(--color-status-warning-base);
    }
  }
}
