.Text {
  font-family: 'Open Sans', sans-serif;
  margin: 0px;
  white-space: pre-wrap;

  display: flex;
  align-items: center;
}

.heading1 {
  font-style: normal;
  font-weight: var(--font-weight-bold); //700

  font-size: var(--font-size-xxl); //32px
  line-height: var(--font-line-height-xxl); //45px
}

.heading2 {
  font-style: normal;
  font-weight: var(--font-weight-bold); //700

  font-size: var(--font-size-xl); //24px
  line-height: var(--font-line-height-xl); //34px
}

.heading3-bold {
  font-style: normal;
  font-weight: var(--font-weight-bold); //700

  font-size: var(--font-size-l); //20px
  line-height: var(--font-line-height-l); //28px
}

.heading3 {
  font-style: normal;
  font-weight: var(--font-weight-light); //400

  font-size: var(--font-size-l); //20px
  line-height: var(--font-line-height-l); //28px
}

.heading4 {
  font-style: normal;
  font-weight: var(--font-weight-bold); //700

  font-size: var(--font-size-md); //16px
  line-height: var(--font-line-height-md); //22px
}

.heading5 {
  font-style: normal;
  font-weight: var(--font-weight-bold); //700

  font-size: var(--font-size-sm); //14px
  line-height: var(--font-line-height-sm); //20px
}

.heading6 {
  font-style: normal;
  font-weight: var(--font-weight-bold); //700

  font-size: var(--font-size-xs); //12px
  line-height: var(--font-line-height-xs); //17px
}

.ui-main-content {
  font-style: normal;
  font-weight: var(--font-weight-light); //400

  font-size: var(--font-size-md); //16px
  line-height: var(--font-line-height-md); //22px
}

.ui-main-bold {
  font-style: normal;
  font-weight: var(--font-weight-bold) !important; //700

  font-size: var(--font-size-md); //16px
  line-height: var(--font-line-height-md); //22px
}

.ui-sub-content {
  font-style: normal;
  font-weight: var(--font-weight-light); //400

  font-size: var(--font-size-sm); //14px
  line-height: var(--font-line-height-sm); //20px
}

.ui-sub-bold {
  font-style: normal;
  font-weight: var(--font-weight-bold); //700

  font-size: var(--font-size-sm); //14px
  line-height: var(--font-line-height-sm); //20px
}

.ui-tiny-content {
  font-style: normal;
  font-weight: var(--font-weight-light); //400

  font-size: var(--font-size-xs); //12px
  line-height: var(--font-line-height-xs); //17px
}

.ui-tiny-semibold {
  font-style: normal;
  font-weight: var(--font-weight-normal); //600

  font-size: var(--font-size-xs); //12px
  line-height: var(--font-line-height-xxxs); //15px
}

.ui-tiny-bold {
  font-style: normal;
  font-weight: var(--font-weight-bold); //700

  font-size: var(--font-size-xs); //12px
  line-height: var(--font-line-height-xs); //17px
}

.paragraph {
  font-style: normal;
  font-weight: var(--font-weight-light); //400

  font-size: var(--font-size-sm); //14px
  line-height: var(--font-line-height-sm); //20px
}

.paragraph2 {
  font-style: normal;
  font-weight: var(--font-weight-light); //400

  font-size: var(--font-size-xs); //12px
  line-height: var(--font-line-height-xs); //17px
}

.paragraph-bold {
  font-style: normal;
  font-weight: var(--font-weight-light); //400

  font-size: var(--font-size-sm); //14px
  line-height: var(--font-line-height-sm); //20px
}

.paragraph2-bold {
  font-style: normal;
  font-weight: var(--font-weight-bold); //700

  font-size: var(--font-size-xs); //12px
  line-height: var(--font-line-height-xs); //17px
}

.small-text {
  font-style: normal;
  font-weight: var(--font-weight-light); //400

  font-size: var(--font-size-xxs); //10px
  line-height: var(--font-line-height-xxs); //16px
}

.small-text-bold {
  font-style: normal;
  font-weight: var(--font-weight-bold); //700

  font-size: var(--font-size-xxs); //10px
  line-height: var(--font-line-height-xss); //12px
}

.link {
  font-style: normal;
  font-weight: var(--font-weight-light); //400

  font-size: var(--font-size-xs); //12px
  line-height: var(--font-line-height-xs); //17px
}
