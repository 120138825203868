.divTextArea {
  width: 100%;
}

.divHeaderTextArea {
  position: relative;
}

.textArea {
  border-radius: 8px;
  > textarea {
    border-radius: 8px;
    height: 100%;
    outline: 0 !important;
    box-shadow: none !important;

    color: var(--color-text-50);

    background: var(--color-background-01) !important;

    border: 1px solid var(--color-background-06) !important;

    &:focus {
      border: 1px solid var(--color-text-400) !important;
    }

    &:disabled {
      color: var(--color-text-50);
      background: var(--color-status-disabled-t15) !important;
    }
  }
}

.errorText {
  margin-top: 5px;
  margin-bottom: 0 !important;
  font-family: var(--font-family);
  font-weight: var(--font-weight-light);
  font-size: var(--font-size-xs);
  line-height: var(--font-line-height-xxs);
  color: var(--color-status-danger-base);
}

.label {
  margin-bottom: 5px;
}

.border {
  position: absolute;

  width: 63px;
  height: 19px;

  top: -2px;
  right: -2px;

  border: 1px solid var(--color-background-04);
  border-radius: 16px;
}
