.EditBody {
  padding: 20px;
  width: 400px;
}

.body-modal-many-itens {
  padding: 20px;
  > div:first-child {
    margin-bottom: 15px;
  }
  > ul {
    padding-inline-start: 25px;
  }
}
