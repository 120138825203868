.Pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0px !important;
  height: 51px;
  
  padding: 10px 18px;

  background: var(--color-background-01);
  border: 1px solid var(--color-background-04);
  border-top: none;

	border-radius: 0 0 8px 8px;

  p {
    font-family: var(--font-family);
    font-weight: var(--font-weight-light);
    font-size: var(--font-size-xs);
    line-height: var(--font-line-height-sm);
    color: var(--color-text-50);
    margin: 0px;
    margin-right: 8px;
  }

  .start {
    display: flex;
    align-items: center;
    justify-content: center;
    
    .count {
      margin-left: 10px;
    }
  }

  .end {
    display: flex;
    align-items: center;
    justify-content: center;

    .count {
      margin-right: 40px;
    }
  }
}