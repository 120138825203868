.ant-notification-notice-btn {
	float: left !important;

	margin-top: 0px !important;
}

.ant-notification-hook-holder, .ant-notification-notice {
	background: var(--color-background-01) !important;
}

.ant-notification-notice-message {
	margin: 0 !important;
}

.ant-notification-notice-description {
	margin-right: 45px !important;

	> .iconToast,
	.iconClose {
		position: absolute !important;

		top: 25px !important;
		right: 25px !important;

		width: 30px !important;
		height: 30px !important;

		display: flex !important;

		border-radius: 100% !important;

		font-size: var(--font-size-l) !important;

		cursor: pointer;
		z-index: 1 !important;
		> i {
			margin: auto !important;
			color: var(--color-white) !important;
		}
	}
}

.toast-sucess-style,
.toast-error-style,
.toast-warning-style {
	border-radius: 8px !important;

	padding: 25px !important;

	> a {
		opacity: 0 !important;
	}
}

.toast-sucess-style {
	> div > div > div > .iconToast {
		background: var(--color-status-success-base) !important;
	}
}

.toast-error-style {
	> div > div > div > .iconToast {
		background: var(--color-status-danger-base) !important;
	}
}
.toast-warning-style {
	> div > div > div > .iconToast {
		background: var(--color-status-warning-base) !important;
	}
}

.iconClose {
	background: var(--color-status-disabled-base) !important;
}

.iconToast:hover {
	opacity: 0 !important;
}
