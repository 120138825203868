html.amplified-text {
  --font-size-xxl: 32px;
  --font-size-xl: 24px;
  --font-size-l: 20px;
  --font-size-md: 16px;
  --font-size-sm: 16px;
  --font-size-xs: 14px;
  --font-size-xxs: 12px;

  --font-line-height-xxl: 45px;
  --font-line-height-xl: 34px;
  --font-line-height-l: 28px;
  --font-line-height-md: 22px;
  --font-line-height-sm: 22px;
  --font-line-height-xs: 19px;
  --font-line-height-xxs: 18px;
  --font-line-height-xxxs: 17px;
	--font-line-height-xss: 14px;
}