.divImage {
	position: relative;
}

.filter {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;

  background-color: var(--color-primary-base);	
  opacity: 0.8;
}

.image {
  width: 100%;
  height: 100vh;

	background-repeat: no-repeat;
	background-size:100%;
	background-size: cover;

	z-index: -1;
}

.icon {
	position: absolute;

	top: 6vh;
	left: 7vh;

	height: 5vh;
	font-size: 5vh;
	width: 5vh;

	z-index: 999;
}