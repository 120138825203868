.margin-bottom {
	margin-bottom: 20px;
}
.Container:not(.margin-bottom) {
	margin-bottom: 0px;
}
.Container {
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;

	.input-container {
		position: relative;
		min-width: 100px;
		width: 100%;

		.PhoneInput {
			height: 36px !important;
			width: 100%;
			background-color: var(--color-background-01);
			box-shadow: none !important;
			border: 1px solid var(--color-background-06) !important;
			border-radius: 8px !important;

			font-family: var(--font-family) !important;
			font-size: var(--font-size-xs) !important;
			line-height: var(--font-line-height-xxs) !important;
			font-weight: normal;
			color: var(--color-text-50) !important;

			.div-dropdown {
				cursor: pointer;
				height: 100%;
				border-top-left-radius: 8px;
				border-bottom-left-radius: 8px;
				border-right: 1px solid var(--color-background-06) !important;
				> div {
					height: 100%;
					align-items: center;
				}

				.flag {
					width: 30px;
					height: 21px;
					border-radius: 12px;
					border: 1px solid var(--color-text-600) !important;
					margin-left: 9px;
					margin-right: 5px;
					> svg {
						border-radius: 12px;
					}
				}

				.disabled-flag {
					margin-right: 9px !important;
					border: 1px solid var(--color-status-disabled-t45) !important;
				}
			}

			.div-dropdown-disabled {
				cursor: no-drop !important;
				border-right: 1px solid var(--color-status-disabled-t45) !important;
			}

			.click {
				background: var(--color-background-02) !important;
			}

			> input {
				outline: none !important;
				border: none !important;
				padding-left: 12px !important;

				background: var(--color-background-01) !important;

				&:disabled {
					background: var(--color-status-disabled-t15) !important;
					cursor: no-drop;
				}
			}
		}

		.disabled-input-phone {
			border-color: var(--color-status-disabled-t45) !important;
			> div {
				background: var(--color-status-disabled-t15) !important;
				cursor: no-drop;
				> div > i {
					cursor: no-drop;
				}
			}
		}
	}

	.div-label {
		display: flex;
		.label {
			margin-right: 3px;
		}
	}
}
