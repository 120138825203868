.HeaderMenu {
  display: flex;
  height: 50px;
  z-index: 4;
  border-bottom: 1px solid var(--color-background-04);
  background: var(--color-background-01);

  .LeftMenu {
    position: relative;
    width: 247px;
    transition: width 0.2s;
    background: var(--color-background-01);
  }

  .collapsed {
    display: flex;

    align-items: center;
    justify-content: center;

    width: 65px;
    transition: width 0.2s;

    background: var(--color-background-01);
  }
  .LeftLogo {
    display: flex;

    align-items: center;
    justify-content: center;

    position: absolute;

    width: 100px;
    height: 30px;

    top: 10px;
    left: 20px;
  }
  .RightMenu {
    width: 100%;
    background: var(--color-background-01);
    align-items: center;
    display: flex;
    justify-content: flex-end;
    > div {
      display: flex;
      align-items: center;
    }

    .divSearch {
      display: inline-block;
      height: 32px;
      margin-left: 25px;
      width: 35%;
    }

    .gear-icon {
      width: 34px;
      height: 34px;
      border-radius: 8px;
      margin-right: 27px;
      &:hover {
        background-color: var(--color-text-600) !important;
      }
    }

    .line {
      width: 1px;
      height: 34px;

      margin-left: 25px;

      background: var(--color-background-04);
    }
  }
}
