.divThemes {
	display: flex;
}

.text, .text-last {
	text-align: center;
	margin-top: 7px;
}

.text {
	margin-right: 20px;
}