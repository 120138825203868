.without-margin-bottom {
	> div {
		margin-bottom: 0 !important;
	}
}

.footer-button {
	display: flex;

	> button:first-child {
		margin-right: 10px;
	}
}
