.divConfirm {
	display: flex;
	margin-top: 30px !important;

	.buttonOk {
		width: 100px !important;
		margin-right: 12px;
	}

	.help-button {
		width: 100px !important;
	}
}

.title {
	 margin-bottom: 10px;
}

.leftBar-sucess,
.leftBar-error,
.leftBar-warning {
	position: absolute;

	width: 6px;
	height: 181px;

	left: 0;
	top: 0;
}

.leftBar-sucess {
	background: var(--color-status-success-base);
}

.leftBar-error {
	background: var(--color-status-danger-base);
}

.leftBar-warning {
	background: var(--color-status-warning-base);
}
