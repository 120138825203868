.Row {
    height: 57px;
    width: 100%;

    border-bottom: 1px solid var(--color-background-04);

    display: flex;
    align-items: center;

    &:hover {
        background-color: var(--color-background-02);
    }
}
