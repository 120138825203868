.Container {
  display: flex;
  margin-bottom: 15px;

  .search {
    width: 100%;
    flex: 1;
  }

  .buttons {
    margin-left: 5px;
    display: flex;
    justify-content: flex-end;

    button {
      margin-left: 5px;
    }
  }
}
